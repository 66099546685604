import React from "react";
import {Stack, Text, Link} from "@chakra-ui/core";
import Button from "@pency/components/controls/Button";
import {GetStaticProps} from "next";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import Head from "next/head";
import {i18n} from "next-i18next";

import LoginForm, {FormData} from "~/forms/LoginForm";
import api from "~/session/api";
import {getLocale} from "~/i18n/selectors";
import {useTranslation} from "~/i18n/hooks";

const SignupRoute: React.FC = () => {
  const [status, setStatus] = React.useState<"init" | "pending" | "resolved" | "rejected">("init");
  const locale = getLocale();
  const t = useTranslation();

  function handleSubmit({slug}: FormData) {
    setStatus("pending");

    return api.signIn(slug);
  }

  return (
    <Stack
      backgroundColor="primary.900"
      backgroundImage="url(./assets/bg.svg)"
      backgroundRepeat="no-repeat"
      backgroundSize={{base: "cover", lg: "60%"}}
      flex={1}
      height="100%"
      minHeight="100vh"
      padding={{base: 6, lg: 20}}
    >
      <Head>
        <link href={`${process.env.URL}/${locale}`} rel="canonical" />
        <link href={process.env.URL} hrefLang="x-default" rel="alternate" />
        <link href={process.env.URL} hrefLang="es" rel="alternate" />
        <link href={`${process.env.URL}/pt`} hrefLang="pt" rel="alternate" />
        <link href={`${process.env.URL}/en`} hrefLang="en" rel="alternate" />
        <title>{t("login.meta.title")}</title>
        <meta content={t("login.meta.description")} name="description" />
        <meta content={t("login.meta.keywords")} name="keywords" />
        <meta content={t("login.meta.title")} name="twitter:title" />
        <meta content={t("login.meta.description")} name="twitter:description" />
        <meta
          content={`${process.env.URL}/og-image-${i18n.language}.jpg`}
          property="twitter:image"
        />
        <meta content={`${process.env.URL}/${locale}`} property="og:url" />
        <meta content={t("login.meta.title")} property="og:title" />
        <meta content={t("login.meta.description")} property="og:description" />
        <meta content={t("login.meta.title")} property="og:image:alt" />
        <meta content={`${process.env.URL}/og-image-${i18n.language}.jpg`} property="og:image" />
        <meta
          content={`${process.env.URL}/og-image-${i18n.language}.jpg`}
          property="og:image:url"
        />
      </Head>
      <Stack
        shouldWrapChildren
        backgroundColor="white"
        borderRadius="lg"
        boxShadow="lg"
        marginX="auto"
        maxWidth={480}
        padding={{base: 6, lg: 10}}
        spacing={6}
        width="100%"
      >
        <Text fontSize="2xl" fontWeight={500}>
          {t("login.title")}
        </Text>
        <LoginForm onSubmit={handleSubmit}>
          {({form, submit}) => (
            <Stack shouldWrapChildren spacing={6}>
              {form}
              <Button
                isLoading={status === "pending"}
                size="lg"
                variantColor="primary"
                width="100%"
                onClick={submit}
              >
                {t("login.action")}
              </Button>
            </Stack>
          )}
        </LoginForm>
        <Text color="gray.600" textAlign="center">
          {t("login.text")}{" "}
          <Link color="primary.500" fontWeight={500} href={`${process.env.SIGNUP_URL}/${locale}`}>
            {t("login.link")}
          </Link>
        </Text>
      </Stack>
    </Stack>
  );
};

export const getStaticProps: GetStaticProps = async ({locale}) => {
  // Return stores so we can build a directory
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
};

export default SignupRoute;
