import React from "react";
import {useForm, Controller} from "react-hook-form";
import FormControl from "@pency/components/form/FormControl";
import {yupResolver} from "@hookform/resolvers";
import * as yup from "yup";
import {Stack} from "@chakra-ui/core";

import SlugInput from "~/tenant/inputs/SlugInput";
import {useTranslation} from "~/i18n/hooks";

export interface FormData {
  slug: string;
}

interface Props {
  defaultValues?: Partial<FormData>;
  onSubmit: (values: Partial<FormData>) => void;
  children: (options: {
    form: JSX.Element;
    isDirty: boolean;
    submit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  }) => JSX.Element;
}

// Form schema
const schema = (t) => yup.object<FormData>({
  slug: yup
    .string()
    .matches(
      /^[a-z0-9_\-\.]+([a-z0-9_])$/,
      t("login.errors.username"),
    )
    .min(4, t("login.errors.composition"))
    .required(t("login.errors.required")),
});

const CreateForm: React.FC<Props> = ({defaultValues, children, onSubmit}) => {
  const t = useTranslation();

  const {handleSubmit: submit, errors, control, formState} = useForm<Partial<FormData>>({
    defaultValues,
    resolver: yupResolver(schema(t)),
  });

  function handleSubmit(formData: FormData) {
    return onSubmit(formData);
  }

  return children({
    submit: submit(handleSubmit),
    isDirty: formState.isDirty,
    form: (
      <form onSubmit={submit(handleSubmit)}>
        <Stack spacing={6}>
          <FormControl error={errors.slug?.message} isInvalid={Boolean(errors.slug)} name="slug">
            <Controller
              as={SlugInput}
              control={control}
              defaultValue=""
              name="slug"
              placeholder={t("login.placeholder")}
              size="lg"
            />
          </FormControl>
        </Stack>
      </form>
    ),
  });
};

export default CreateForm;
