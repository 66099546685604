import React, {ChangeEvent} from "react";
import Input from "@pency/components/inputs/Input";
import {InputProps, InputGroup, InputLeftAddon} from "@chakra-ui/core";

interface Props extends Omit<InputProps, "value" | "onChange"> {
  value?: string;
  onChange?: (value: Props["value"]) => void;
}

const SlugInput: React.FC<Props> = ({onChange, ...props}) => {
  const ref = React.useRef<HTMLInputElement>();

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    return onChange(event.target.value.toLowerCase());
  }

  return (
    <InputGroup backgroundColor="gray.100" borderRadius="md" overflow="auto">
      <InputLeftAddon
        children="pency.app/"
        backgroundColor="transparent"
        borderWidth={0}
        color="gray.500"
        marginRight={0}
        paddingRight={0.5}
        onClick={() => ref.current.focus()}
      />
      <Input ref={ref} paddingLeft={0.5} onChange={handleChange} {...props} />;
    </InputGroup>
  );
};

export default SlugInput;
